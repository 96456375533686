import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../store/withReducer";
import reducer from './store';
import {hideMessage} from './store/messageSlice';


export function MessageBox() {
    const dispatch = useDispatch()
    const [seconds, setSeconds] = useState(5); //sets the timer seconds.
    const message = useSelector(({message}) => message.message)


    useEffect(() => {

        if (message.state === true) {
            if (seconds > 0) {
                setTimeout(() => setSeconds(seconds - 1), 1000);
            } else {
                setSeconds(5);
                dispatch(hideMessage())
            }
        }
    }, [message, seconds, dispatch])

    return (
        <>
            {message.state === true ?
                <div className="alert-header w-full px-4 py-1 opacity-90 fixed">
                    <div className="flex items-center justify-center w-full p-2 bg-red-500 shadow text-white">
                        <p className="justify-center">{message.options.message}</p>

                    </div>
                </div>
                : null}
        </>
    )
}

export default withReducer('message', reducer)(MessageBox);
