import {createSlice, createEntityAdapter} from '@reduxjs/toolkit';
import DanishFlag from "../../../Assets/danishFlag.png";
import EnglishFlag from "../../../Assets/englishFlag.png";


const languageAdapter = createEntityAdapter({});

export const {selectAll: selectLanguages, selectById: selectLanguageById} = languageAdapter.getSelectors(
    state => state.language.language
);

const languageSlice = createSlice({
    name: 'language/language',
    initialState: languageAdapter.getInitialState({
        selectedLanguage: {id: 1, name: 'Dansk', icon: DanishFlag, i18Name: 'da'},
        allLanguages: [{id: 1, name: 'Dansk', icon: DanishFlag, i18Name: 'da'},
            {id: 2, name: 'English', icon: EnglishFlag, i18Name: 'en'}]
    }),
    reducers: {
        setLanguage: {
            reducer: (state, action) => {
                state.selectedLanguage = action.payload;
            },
        },
    },
    extraReducers: {}
});

export const {
    setLanguage
} = languageSlice.actions;

export default languageSlice.reducer;
