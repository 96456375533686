import {Fragment, useEffect} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {SelectorIcon} from '@heroicons/react/solid'
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../store/withReducer";
import reducer from './store';
import {setLanguage} from "./store/languageSlice";
import {useTranslation} from 'react-i18next';


export function LanguageSelector() {
    const language = useSelector(({language}) => language.language)
    const dispatch = useDispatch()
    const {i18n} = useTranslation();

    useEffect(() => {
    }, [language])

    function languageChange(e) {
        dispatch(setLanguage(e));
        i18n.changeLanguage(e.i18Name, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
        });
    }


    return (
        <Listbox style={{minWidth: "40px"}} value={language.selectedLanguage}
                 onChange={(event) => languageChange(event)}>
            <div className="relative mt-1">
                <Listbox.Button
                    className="flex items-center relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                    <span className="pr-2">
                      <img alt="sprog" width={40} height={20} src={language.selectedLanguage.icon}/>
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
              />
            </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options
                        className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {language.allLanguages.map((lang, languageIdx) => (
                            <Listbox.Option
                                key={languageIdx}
                                className={({active}) =>
                                    `${active ? 'bg-red-200' : ''} cursor-pointer select-none py-2 flex justify-center `
                                }
                                value={lang}
                            >
                                {({}) => (
                                    <>
                                      <span
                                          className=""
                                      >
                                          <img alt="language" width={40} height={40} src={lang.icon}/>
                                    </span>
                                    </>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export default withReducer('language', reducer)(LanguageSelector);
