import React from 'react';
import logo from './Assets/APCOA_Parking_logo_web.png';
import Welcome from './app/welcome/Welcome'
import Voucher from './app/voucher/Voucher'
import Receipt from './app/receipt/Receipt'
import Confirm from './app/confirm/Confirm'
import axios from 'axios'
import LanguageSelector from './app/features/LanguageSelector';
import {
    Route,
    Routes,
    Navigate,useSearchParams
} from "react-router-dom";
import MessageBox from './app/features/MessageBox'

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

function RedirectTablet() {
    const [searchParams,setSearchParams] = useSearchParams();
    const uid = searchParams.get("uid");
    return (<Navigate to={`/${uid}`}/>)
}

function RedirectToBakken(){
    window.location.href = 'https://bakken.mobilparkering.dk/bakken';
}
function App() {
    // 'const routes = useRoutes([
    //     {path: '/tablet?uid=:uid', element: <RedirectTablet/>},
    //     {path: '/:uid', element: <Welcome/>},
    //    
    //     {path: '/voucher', element: <Voucher/>},
    //     {path: '/:uid/confirm', element: <Confirm/>},
    //     {path: '/:uid/receipt', element: <Receipt/>},
    //     {path: '/confirm', element: <Confirm/>},
    //     {path: '/receipt', element: <Receipt/>},
    // ]);'

    return (
        <div>

            <div className=" pb-10 pt-5 flex relative py-8" style={{backgroundColor: '#ffffff'}}>
                <div className="absolute w-full">
                    <div className="w-full flex justify-center">
                        <div className="py-2 w-50">
                            <img alt="Apcoa" src={logo}/>
                        </div>
                    </div>
                </div>
                <div className="ml-auto z-10">
                    <LanguageSelector/>
                </div>

            </div>

            <div>
                <div className="sticky">
                    <MessageBox/>
                </div>

                <Routes>
                    <Route path="/bakken" element={
                        <RedirectToBakken/>
                    }/>
                    <Route path="/tablet" element={
                        <RedirectTablet/>
                    }/>
                    <Route path="/:uid" element={
                        <Welcome/>
                    }/>
                    <Route path="/voucherparkering" element={
                        <Voucher/>
                    }/>
                    <Route path="/:uid/confirm" element={
                        <Confirm/>
                    }/>
                    <Route path="/:uid/receipt" element={
                        <Receipt/>
                    }/>
                    <Route path="/receipt" element={
                        <Receipt/>
                    }/>
                    <Route path="/confirm" element={
                        <Confirm/>
                    }/>
                </Routes>
            </div>
        </div>
    )

}

export default App;
