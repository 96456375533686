import {combineReducers} from '@reduxjs/toolkit';
import language from './languageSlice';
import message from './messageSlice';

const reducers = combineReducers({
    language,
    message
});

export default reducers;
