import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    state: false,
    options: {
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
        },
        autoHideDuration: 6000,
        message: 'Der skete en fejl. Prøv igen.',
        variant: null
    }
};
const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        showMessage: (state, action) => {
            if (state.state === false) {
                state.state = true;
                state.options = {
                    ...initialState.options,
                    ...action.payload
                };
            }
        },
        hideMessage: (state, action) => {
            state.state = false;
            state.options = {
                ...initialState.options,
                ...action.payload
            };
        }
    }
});
export const {hideMessage, showMessage} = messageSlice.actions;
export default messageSlice.reducer;